/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable mdx/no-unused-expressions */
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { MainPicture } from '../components/MainPicture';
import { colors } from '../components/layout/GlobalStyles';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { StyledTemplate } from '../components/layout/StyledComponents';
import { servicesMenu } from '../components/layout/navMenu';
import { Seo } from '../components/Seo'

const Service = ({ data }) => {
    const { mdx } = data;
    const [menu, setMenu] = useState({ items: null, name: null });
    const [active, setActive] = useState(false);

    const itemClick = item => {
        if (item.menu) {
            setActive(true);
            setMenu({ items: item.menu, name: item.name });
        }
        if (menu.name === item.name) {
            setActive(!active);
        } else return;
    };
    return (
        <DefaultLayout previousPage={mdx.frontmatter.type} currentPage={mdx.frontmatter.breadCrumbs}>
            <StyledTemplate>
            <Seo
                title={mdx.frontmatter.meta.title}
                description={mdx.frontmatter.meta.description}
            />
                <MainPicture title={mdx.frontmatter.title} className="main-picture">
                    {mdx && mdx.frontmatter.images && (
                        <>
                            <GatsbyImage
                                alt={mdx.frontmatter.images[0].name}
                                className="underlayer"
                                quality={90}
                                placeholder="none"
                                imgStyle={{ borderRadius: '20px' }}
                                image={mdx.frontmatter.images[0].childImageSharp.gatsbyImageData}
                            />
                            <GatsbyImage
                                alt={mdx.frontmatter.images[0].name}
                                placeholder="none"
                                imgStyle={{ borderRadius: '20px' }}
                                quality={90}
                                image={mdx.frontmatter.images[0].childImageSharp.gatsbyImageData}
                            />
                        </>
                    )}
                </MainPicture>
                <div className="flex-container">
                    <div className="text-container">
                        <MDXRenderer images={mdx.frontmatter.images}>{mdx.body}</MDXRenderer>
                    </div>
                    <div className="nav-panel">
                        <p className="list-title">Our Services</p>
                        <ul>
                            {servicesMenu.map(item => {
                                return (
                                    <>
                                        <div key={item.name} className="nav-item">
                                            <div
                                                onClick={() => itemClick(item)}
                                                className="flex-item"
                                                role="navigation"
                                            >
                                                {active && item.name === menu.name && menu.items ? (
                                                    <>
                                                        {' '}
                                                        <li
                                                            style={{
                                                                color: `${colors.blueMedium}`,
                                                            }}
                                                        >
                                                            {item.name}
                                                        </li>
                                                        <StaticImage
                                                            className=" item-arrow"
                                                            src="../images/svgs/arrow-up-grey.svg"
                                                            placeholder="none"
                                                            alt="black arrow pointing up"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {' '}
                                                        <li>{item.name}</li>
                                                        <StaticImage
                                                            className="item-arrow"
                                                            src="../images/svgs/arrow-down-grey.svg"
                                                            placeholder="none"
                                                            alt="black arrow pointing down"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <div className="sub-dropdown">
                                                {active && item.name === menu.name && menu.items
                                                    ? menu.items.map(items => (
                                                          <Link
                                                                key={items.name}
                                                              style={{ textDecoration: 'none' }}
                                                              to={`/${items.path}`}
                                                          >
                                                              <li key={items.name}>{items.name}</li>
                                                          </Link>
                                                      ))
                                                    : null}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                {/* {mdx.exports.questions && <Faq questions={mdx.exports.questions} />} */}
            </StyledTemplate>
        </DefaultLayout>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                type
                breadCrumbs
                slug
                images {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
            exports {
                questions {
                    answer
                    question
                }
            }
            body
        }
    }
`;

export default Service;
