import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';

const StyledMainPicture = styled.div`
    h1 {
        position: absolute;
        bottom: 0.25em;
        left: 0.25em;
        font-size: 32px;

        line-height: 42px;
        z-index: 5;
        color: ${colors.white};
    }
    .shadow {
        position: absolute;
        top: 0em;
        width: 100%;
        background: linear-gradient(
                0deg,
                #1a2c41 0%,
                rgba(26, 44, 65, 0.17) 51.62%,
                rgba(255, 255, 255, 0) 111.6%
            ),
            url(image.png);
        border-radius: 20px;
        height: 100%;
        z-index: 1;
    }
    .underlayer {
        z-index: -2;
        opacity: 0.7;
        filter: blur(41px);
        border-radius: 20px;
        position: absolute;
        top: 2em;
        left: 2em;
        width: 90%;
        max-height: 226px;
        border-radius: 20px;
    }
    .image-container {
        img {
            z-index: -1;
        }
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-height: 226px;
    }
    @media ${device.tablet} {
        h1 {
            line-height: 48px;
            position: absolute;
            bottom: 0.75em;
            left: 1em;
            font-size: 42px;
            z-index: 5;
            color: ${colors.white};
        }
        .underlayer {
            max-height: 356px;
        }
        .image-container {
            margin: 0 auto;
            width: 700px;
            max-height: 356px;
            img {
                width: 700px;
            }
        }
    }
    @media ${device.laptop} {
        .underlayer {
        }
        .image-container {
            margin: 0 auto;
            width: 940px;
            img {
                width: 940px;
            }
        }
    }
    @media ${device.laptopL} {
        .image-container {
            height: 540px;
            width: 1280px;
            margin: 0 auto;
            img {
                max-height: 540px;
                width: 1280px;
            }
            .underlayer {
                position: absolute;
                top: 1em;
                width: 90%;
                height: 340px;
            }
        }
    }
`;

interface Props {
    title: string;
}

export const MainPicture: React.FC<Props> = ({ children, title }) => {
    return (
        <StyledMainPicture>
            <div className="image-container">
                <div className="shadow"></div>
                <h1>{title}</h1>
                {children}
            </div>
        </StyledMainPicture>
    );
};
